const Environments = Object.freeze({
    BASE_DOMAIN: 'https://api.dev.re-tool.jp'
})
export default function setEnvironments() {
    //console.log = () => {};
    window.RETOOL_ENV = Environments;
}

export const USER_POOL_ID = "ap-southeast-2_eiaRtawIh";
export const CLIENT_ID         = "7f6o1251mv9qdssgjtcicemg2b";
export const BASE_DOMAIN       = 'https://api.dev.re-tool.jp';
export const ADMIN_BASE_DOMAIN = 'https://admin.dev.re-tool.jp';
