'use strict';
import React from 'react';

export default function InformationBox(props) {
    console.log("InformationBox", props);
    return (
            <div className="card h-100 shadow-sm p-2">
                <div className="card-body overflow-auto">
                    <div className="row">
                        <div className="col-sm-6">
                            <h6 className="pb-3 retool-dashboard-label-default">進捗データ</h6>
                            <div className="border retool-dashboard-summary-border retool-dashboard-summary-body-default">
                                <div className="row">
                                     <div className="col-sm-4">
                                        <h6 className="py-3 px-4 retool-dashboard-label-default">計画</h6>
                                        <div className="border mx-3 retool-dashboard-summary-border my-2 retool-dashboard-summary-default">
                                            <div className="text-center retool-dashboard-summary-label py-4">
                                            {Number(props.summary.totalProgress.plan).toFixed(1)}
                                            </div>
                                        </div>
                                     </div>
                                     <div className="col-sm-4">
                                        <h6 className="py-3 px-4 retool-dashboard-label-default">実績</h6>
                                        <div className="border mx-3 retool-dashboard-summary-border my-2 retool-dashboard-summary-default">
                                            <div className="text-center retool-dashboard-summary-label py-4 ">
                                            {Number(props.summary.totalProgress.result).toFixed(1)}
                                            </div>
                                        </div>
                                     </div>
                                     <div className="col-sm-4">
                                        <h6 className="py-3 px-4 retool-dashboard-label-default">達成率</h6>
                                        <div className="border mx-3 retool-dashboard-summary-border my-2 retool-dashboard-summary-default">
                                            <div className="text-center retool-dashboard-summary-label py-4">
                                            {Number(props.summary.totalProgress.complete).toFixed(2)}%
                                            </div>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <h6 className="pb-3 retool-dashboard-label-default">稼動データ</h6>
                            <div className="border retool-dashboard-summary-border retool-dashboard-summary-body-default">
                                <div className="row">
                                     <div className="col-sm-4">
                                        <h6 className="py-3 px-4 retool-dashboard-label-default">計画</h6>
                                        <div className="border mx-3 retool-dashboard-summary-border my-2 retool-dashboard-summary-default">
                                            <div className="text-center retool-dashboard-summary-label py-4">
                                            {Number(props.summary.totalOperation.plan).toFixed(1)}
                                            </div>
                                        </div>
                                     </div>
                                     <div className="col-sm-4">
                                        <h6 className="py-3 px-4 retool-dashboard-label-default">実績</h6>
                                        <div className="border mx-3 retool-dashboard-summary-border my-2 retool-dashboard-summary-default">
                                            <div className="text-center retool-dashboard-summary-label py-4 ">
                                            {Number(props.summary.totalOperation.result).toFixed(1)}
                                            </div>
                                        </div>
                                     </div>
                                     <div className="col-sm-4">
                                        <h6 className="py-3 px-4 retool-dashboard-label-default">達成率</h6>
                                        <div className="border mx-3 retool-dashboard-summary-border my-2 retool-dashboard-summary-default">
                                            <div className="text-center retool-dashboard-summary-label py-4">
                                            {Number(props.summary.totalOperation.complete).toFixed(2)}%
                                            </div>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    )
}

