export const didMnt = (currentTerm, terms, members, teams, projects, phases, occupations) => {
    return {
        type: 'DID_MOUNT',
        payload: { currentTerm, terms, members, teams, projects, phases, occupations}
    };
}
export const updateSelectCtlTeams = (value, plans, operations, progresses) => {
    return {
        type: 'UPDATE_SELECT_CTL_TEAMS',
        payload: { value, plans, operations, progresses }
    };
}
export const updateSelectCtlMembers = (value) => {
    return {
        type: 'UPDATE_SELECT_CTL_MEMBERS',
        payload: { value }
    };
}
export const updateSelectCtlProjects = (value) => {
    return {
        type: 'UPDATE_SELECT_CTL_PROJECTS',
        payload: { value }
    };
}
export const updateSelectCtlPhases = (value) => {
    return {
        type: 'UPDATE_SELECT_CTL_PHASES',
        payload: { value }
    };
}
