import { createStore as reduxCreateStore, combineReducers } from "redux";
import { authUser } from "@common/reducers/auth/AuthUser";
import { loading } from "@common/reducers/loading/Loading";
import { sideMenu } from "@common/reducers/sidemenu/SideMenu";
import { terms, members, teams, projects, phases, conditions, summary, occupations } from "./reducers";

export default function createStore() {
    const store = reduxCreateStore(
        combineReducers({
            authUser,
            terms, members, teams, projects, phases, conditions, loading, sideMenu, summary, occupations
        })
    );
    return store;
}
