import { connect } from 'react-redux';
import * as AuthUser from '@common/actions/auth/AuthUser';
import * as updateLoading  from '@common/actions/loading/Loading';
import * as updateSideMenu  from '@common/actions/sidemenu/SideMenu';
import * as actions from '../actions';
import App from '../App';

const mapStateToProps = ({authUser, terms, members, teams, projects, phases, conditions, loading, sideMenu, summary, occupations}) => {
//    const  {authUser, terms, members, teams, conditions} = state;
    return {authUser, terms, members, teams, projects, phases, conditions, loading, sideMenu, summary, occupations}
}

const mapDispatchToProps = dispatch => {
    return {
        auth: (userInfo) => dispatch(AuthUser.auth(userInfo)),

        didMnt: (currentTerm, terms, members, teams, projects, phases, occupations) =>
            dispatch(actions.didMnt(currentTerm, terms, members, teams, projects, phases, occupations)),
        updateSelectCtlTeams: (value, plans, operations, progresses) => dispatch(actions.updateSelectCtlTeams(value, plans, operations, progresses)),
        updateSelectCtlMembers: (value) => dispatch(actions.updateSelectCtlMembers(value)),
        updateSelectCtlProjects: (value) => dispatch(actions.updateSelectCtlProjects(value)),
        updateSelectCtlPhases: (value) => dispatch(actions.updateSelectCtlPhases(value)),
        updateLoading: (value) => dispatch(updateLoading.updateLoading(value)),
        updateSideMenu: (value) => dispatch(updateSideMenu.updateSideMenu(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
