"use strict";
import React from "react";
import AuthUser from "@common/auth/AuthUser";

export default function Header(props) {
  //    const userInfo = AuthUser.getUserInfo();
  const userInfo = props.userInfo;
  return (
    <nav
      className="py-0 pl-0 navbar navbar-expand-lg"
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <a className="m-0 p-0 navbar-brand" href="/">
        <img
          src="/images/header_logo.jpg"
          style={{ height: "40px", width: "108px" }}
        />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#retoolMenu"
        aria-controls="retoolMenu"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className="collapse navbar-collapse justify-content-end"
        id="bs-navi"
      >
        <span className="navbar-text">{userInfo.name}</span>
        <ul className="navbar-nav">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-user fa-fw"></i>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdown"
            >
              <a className="dropdown-item" href="#">
                {userInfo.username}
              </a>
              <div className="dropdown-divider"></div>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => AuthUser.signOut()}
              >
                ログアウト
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}