'use strict';
import retool_auth from '@common/auth/AuthUser';
import { BASE_DOMAIN } from '@setEnv';

export const ERRORS = {
    "INVALID_TOKEN": "invalid token.",
    "UNAUTHORIZED": "unauthorized.",
    "NOT_FOUND": "not found.",
    "INTERNAL_SERVER_ERROR": Error('internal server error.'),
    "BAD_GATEWAY": Error('bad gateway.'),
    "UNHANDLED_ERROR": Error('unhandled error.')
}
const handleErrors = (res) => {
    console.log("********************")
    console.log(res)
    console.log("********************")
    
  if (res.ok) {
    return res;
  }

  switch (res.status) {
    case 400: throw ERRORS.INVALID_TOKEN;
    case 401: throw ERRORS.UNAUTHORIZED;
    case 404: throw ERRORS.NOT_FOUND;
    case 500: throw ERRORS.INTERNAL_SERVER_ERROR;
    case 502: throw ERRORS.BAD_GATEWAY;
    default:  throw ERRORS.UNHANDLED_ERROR;
  }
};
/**
 *
 */
export async function getCalendar(member_id, date) {
    const id = retool_auth.getToken();
    const {companyId} = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/results/calendars/${member_id}/${date}`;
//    const URL = `https://hu802eqlth.execute-api.ap-southeast-2.amazonaws.com/v1/${companyId}/projects`;
//    const URL = "/reports/progressSummary.json";
//    const URL = `/results/calendar/${date}.json`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
         "Authorization": id,
         "Content-Type": "application/json"
        }
    }
//    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .catch((e) => { throw Error(e); })
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            console.log(JSON.stringify(json));
            return Reflect.get(json, "data")
        })
}

/**
 *
 */
export async function getClientOperations(member_id, date) {
    const id = retool_auth.getToken();
    const {companyId} = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/results/clientLog/${member_id}/${date}`;
//    const URL = `https://hu802eqlth.execute-api.ap-southeast-2.amazonaws.com/v1/${companyId}/projects`;
//    const URL = "/reports/progressSummary.json";
//    const URL = `/results/clientOperations/${date}.json`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
         "Authorization": id,
         "Content-Type": "application/json"
        }
    }
//    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .catch((e) => { throw Error(e); })
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            console.log(JSON.stringify(json));
            return Reflect.get(json, "data")
        });

}

/**
 *
 */
export async function getPlans(member_id) {
    const id = retool_auth.getToken();
    const {companyId} = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/results/plans2/${member_id}`;
    // const URL = `https://hu802eqlth.execute-api.ap-southeast-2.amazonaws.com/v1/${companyId}/projects`;

    //WHL-216 暫定対応
    // const URL = `/results/plan/${member_id}.json`;

    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
         "Authorization": id,
         "Content-Type": "application/json"
        }
    }
//    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .catch((e) => { throw Error(e); })
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            // console.log("@@",JSON.stringify(json));
            return Reflect.get(json, "data")
        });
}


export async function getPlansPerTerm(member_id, term_id) {
    const id = retool_auth.getToken();
    const {companyId} = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/results/plans2/${member_id}/${term_id}`;
    // const URL = `https://hu802eqlth.execute-api.ap-southeast-2.amazonaws.com/v1/${companyId}/projects`;

    //WHL-216 暫定対応
    // const URL = `/results/plan/${member_id}.json`;

    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
         "Authorization": id,
         "Content-Type": "application/json"
        }
    }
//    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .catch((e) => { throw Error(e); })
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            // console.log("@@",JSON.stringify(json));
            return Reflect.get(json, "data")
        });
}

export async function putPlans(item) {
    console.log("putPlans", item)
    const id = retool_auth.getToken();
    const {companyId} = retool_auth.getUserInfo();
    //TODO:username
    const URL = `${BASE_DOMAIN}/${companyId}/results/plans2/${item.memberId}/${item.term}`;
//    const URL = `https://fuo7ewe73h.execute-api.ap-southeast-2.amazonaws.com/v1/${companyId}/results/operations/${item.member_id}/${item.datetime}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
         "Authorization": id,
         "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item.plan
        })
    }
    console.log(URL);
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}

/**
 *
 */
export async function getOperations(member_id) {
    const id = retool_auth.getToken();
    const {companyId} = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/results/operations/${member_id}`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
         "Authorization": id,
         "Content-Type": "application/json"
        }
    }
//    const URL = "./operations.json";
//    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => {
            // console.log(JSON.stringify(json));
            return Reflect.get(json, "data")
        });

}
export async function getOperationsPerDay(member_id, datetime) {
    const id = retool_auth.getToken();
    const {companyId} = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/results/operations/${member_id}/${datetime}`;
    console.log("@@", URL)
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
         "Authorization": id,
         "Content-Type": "application/json"
        }
    }
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => {
            console.log("@@",JSON.stringify(json));
            return Reflect.get(json, "data")
        });

}




export async function putOperation(member_id, item) {
    const id = retool_auth.getToken();
    const {companyId} = retool_auth.getUserInfo();
    //TODO:username
    const URL = `${BASE_DOMAIN}/${companyId}/results/operations/${member_id}/${item.datetime}`;
//    const URL = `https://fuo7ewe73h.execute-api.ap-southeast-2.amazonaws.com/v1/${companyId}/results/operations/${item.member_id}/${item.datetime}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
         "Authorization": id,
         "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item
        })
    }
    console.log(URL);
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}

export async function deleteOperation(member_id, item) {
    const id = retool_auth.getToken();
    const {companyId} = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/results/operations/${member_id}/${item.datetime}`;
    const OPTIONS = {
        method: "DELETE",
        mode: "cors",
        cache: "no-cache",
        headers: {
         "Authorization": id,
         "Content-Type": "application/json"
        }
    }
    console.log(URL);
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}

/**
 *
 */
export async function getItems() {
    const id = retool_auth.getToken();
    const {companyId} = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/results/items`;
//    const URL = `https://hu802eqlth.execute-api.ap-southeast-2.amazonaws.com/v1/${companyId}/items`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
         "Authorization": id,
         "Content-Type": "application/json"
        }
    }
    //const URL = "./items.json";

    return await fetch(URL, OPTIONS)
//    return await fetch(URL)
        .then(res => res.json())
        .then(json => {
            console.log(JSON.stringify(json));
            return Reflect.get(json, "data")
        });

}



/**
 *
 */
export async function putItem(item) {
    console.log(JSON.stringify(item));
    const id = retool_auth.getToken();
    const {companyId} = retool_auth.getUserInfo();
    //const URL = `https://hu802eqlth.execute-api.ap-southeast-2.amazonaws.com/v1/${companyId}/results/items/${item.id}`;
    const URL = `${BASE_DOMAIN}/${companyId}/results/items/${item.id}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
         "Authorization": id,
         "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item
        })
    }
//    const URL = "./items.json";
//    return await fetch(URL)
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => {
            console.log(JSON.stringify(json));
            return Reflect.get(json, "data")
        });

}


// 売れる相性分析
export async function getCommodityPredict(customerId) {
    const id = retool_auth.getToken();
    const {companyId} = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/predicts/${customerId}/commodity`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
         "Authorization": id,
         "Content-Type": "application/json"

        }
    }
    return await fetch(URL, OPTIONS)
        .catch((e) => { throw Error(e); })
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            console.log(JSON.stringify(json));
            return json
        });
}

export async function getCustomerPredict(commodityId) {
    const id = retool_auth.getToken();
    const {companyId} = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/predicts/${commodityId}/customer`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
         "Authorization": id,
         "Content-Type": "application/json"

        }
    }
    return await fetch(URL, OPTIONS)
        .catch((e) => { throw Error(e); })
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            console.log(JSON.stringify(json));
            return json
        });
}


export async function getItemResult(target_id, target,from, to) {
    const id = retool_auth.getToken();
    const {companyId} = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/results/items/${target_id}/${target}/${from}/${to}`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
         "Authorization": id,
         "Content-Type": "application/json"
        }
    }
    //const URL = "./items.json";

    return await fetch(URL, OPTIONS)
//    return await fetch(URL)
        .then(res => res.json())
        .then(json => {
            console.log(JSON.stringify(json));
            return Reflect.get(json, "data")
        });

}