import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import setEnvironments from '@setEnv';
setEnvironments();

import App from './containers/App';
import createStore from './createStore';




const store = createStore();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById('contents')
);
