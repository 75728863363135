const termsInitialState = {
    currentTerm: -1,
    list: []
}

export function terms(state = termsInitialState, action) {
    console.log(action);
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'DID_MOUNT':
            newState.list = action.payload.terms;
            newState.currentTerm = action.payload.currentTerm;
            break;
        default:
            return state;
    }
    return newState;
};

const initialState = {
    list: []
}

export function members(state = initialState, action) {
    console.log(action);
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'DID_MOUNT':
            // 現在日付
            const today = new Date()
            const date = today.getFullYear() * 10000 + (today.getMonth() + 1) * 100 + today.getDate()

            const baseMembers = action.payload.members;
            const allMembers = baseMembers.map((baseMember) => {
                if (!baseMember.from) {
                    baseMember.from = date
                } else {
                    baseMember.from = +baseMember.from
                }
                if (!baseMember.to) {
                    baseMember.to = date
                } else {
                    baseMember.to = +baseMember.to
                }
                return baseMember
            })
            const activeMembers = allMembers.filter(allMember => (date >= allMember.from) && (date <= allMember.to))
            newState.list = activeMembers
            break;
        default:
            return state;
    }
    return newState;
};

export function teams(state = initialState, action) {
    console.log(action);
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'DID_MOUNT':
            newState.list = action.payload.teams;
            break;
        default:
            return state;
    }
    return newState;
};

export function projects(state = initialState, action) {
    console.log(action);
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'DID_MOUNT':
            newState.list = action.payload.projects;
            break;
        default:
            return state;
    }
    return newState;
};

export function phases(state = initialState, action) {
    console.log(action);
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'DID_MOUNT':
            newState.list = action.payload.phases.sort((a, b) => a.id - b.id);
            break;
        default:
            return state;
    }
    return newState;
};

export function occupations(state = initialState, action) {
    console.log(action);
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'DID_MOUNT':
            newState.list = action.payload.occupations;
            break;
        default:
            return state;
    }
    return newState;
};

const conditionsInitialState = {
    selectedTeam: -2,
    selectedMember: -1,
    selectedProject: -1,
    selectedPhase: -1,
    list: []
}

export function conditions(state = conditionsInitialState, action) {
    console.log(action);
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'UPDATE_SELECT_CTL_TEAMS':
            newState.selectedTeam = action.payload.value;
            newState.selectedMember = -1;
            newState.selectedProject = -1;
            newState.selectedPhase = -1;
            break;
        case 'UPDATE_SELECT_CTL_MEMBERS':
            newState.selectedMember = action.payload.value;
            break;
        case 'UPDATE_SELECT_CTL_PROJECTS':
            newState.selectedProject = action.payload.value;
            newState.selectedPhase = -1;
            break;
        case 'UPDATE_SELECT_CTL_PHASES':
            newState.selectedPhase = action.payload.value;
            break;
        default:
            return state;
    }
    return newState;
};

const summaryInitialState = {
    selectedTeam: -2,
    selectedMember: -1,
    selectedProject: -1,
    selectedPhase: -1,
    currentTerm: -1,
    teams: [],
    members: [],
    projects: [],
    phases: [],
    plans: [],
    operations: [],
    label: [],
    occupations: [],
    totalOperation: { plan: 0, result: 0, complete: 0 },
    operationAchievementRate: {},
    totalProgress: { plan: 0, result: 0, complete: 0 },
    actionAchievementRate: {}
}


export function summary(state = summaryInitialState, action) {
    console.log(action);
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'DID_MOUNT':
            newState.teams = action.payload.teams;
            newState.members = action.payload.members;
            newState.projects = action.payload.projects;
            newState.phases = action.payload.phases.sort((a, b) => a.id - b.id);
            newState.currentTerm = action.payload.currentTerm;
            newState.occupations = action.payload.occupations;
            newState.plans.forEach((plan) => {
                plan.planComplete = Object.values(plan.planComplete).reduce((arr, complete) => {
                    arr = { ...complete, ...arr }
                    return arr
                }, {})

                plan.planCompleteRate = Object.values(plan.planCompleteRate).reduce((arr, completeRate) => {
                    arr = { ...completeRate, ...arr }
                    return arr
                }, {})

                plan.planTaskOperationTimes = Object.values(plan.planTaskOperationTimes).reduce((arr, taskOperationTimes) => {
                    arr = { ...taskOperationTimes, ...arr }
                    return arr
                }, {})
            })

            newState.label = PhaseList(newState);

            break;
        case 'UPDATE_SELECT_CTL_TEAMS':
            newState.selectedTeam = action.payload.value;
            newState.selectedMember = -1;
            newState.selectedProject = -1;
            newState.selectedPhase = -1;

            newState.plans = action.payload.plans;
            newState.operations = action.payload.operations;
            newState.progresses = action.payload.progresses;

            newState.plans.forEach((plan) => {
                plan.planComplete = Object.values(plan.planComplete).reduce((arr, complete) => {
                    arr = { ...complete, ...arr }
                    return arr
                }, {})

                plan.planCompleteRate = Object.values(plan.planCompleteRate).reduce((arr, completeRate) => {
                    arr = { ...completeRate, ...arr }
                    return arr
                }, {})

                plan.planTaskOperationTimes = Object.values(plan.planTaskOperationTimes).reduce((arr, taskOperationTimes) => {
                    arr = { ...taskOperationTimes, ...arr }
                    return arr
                }, {})
            })

            newState.label = PhaseList(newState);
            OperationsInfo(newState);
            ProgressesInfo(newState);
            break;
        case 'UPDATE_SELECT_CTL_MEMBERS':
            newState.selectedMember = action.payload.value;
            newState.label = PhaseList(newState);
            OperationsInfo(newState);
            ProgressesInfo(newState);
            break;
        case 'UPDATE_SELECT_CTL_PROJECTS':
            newState.selectedProject = action.payload.value;
            newState.selectedPhase = -1;
            newState.label = PhaseList(newState);
            OperationsInfo(newState);
            ProgressesInfo(newState);
            break;
        case 'UPDATE_SELECT_CTL_PHASES':
            newState.selectedPhase = action.payload.value;
            newState.label = PhaseList(newState);
            OperationsInfo(newState);
            ProgressesInfo(newState);
            break;
        default:
            return state;
    }
    return newState;
};
//前処理
function Preprocessing(state) {
    const teams = state.teams.filter(team => state.selectedTeam == -1 || state.selectedTeam == team.id).map(team => String(team.id));
    const isExist = (teamId) => teams.includes(teamId)
    const teamMembers = state.members.filter(member => {
        if (member.team_list.some(isExist)) {
            return member
        }
    }).map(member => member.id);
    const members = teamMembers.filter(memberId => state.selectedMember == -1 || state.selectedMember == memberId)
    // コントローラーから絞り込み
    const selectedOperations = state.operations.filter(operation => members.includes(String(operation.memberId)))
    const selectedProgresses = state.progresses.filter(progress => members.includes(progress.member_id))
    const selectedPlans = state.plans.filter(plan => members.includes(plan.member_id))

    return { "selectedOperations": selectedOperations, "selectedPlans": selectedPlans, "selectedProgresses": selectedProgresses }
}

function PhaseList(state) {
    return state.phases.reduce((obj, phase) => {
        if (phase.projectId == state.selectedProject || state.selectedProject == -1) {
            if (state.selectedTeam == -1) {
                if (state.selectedPhase == -1 || state.selectedPhase == phase.id) {
                    obj[phase.id] = phase.name
                }
            } else {
                //--------state.selectedTeam が選択済みの時
                const occupationIds = state.occupations.filter((occupation) => {
                    if (occupation.project_list.includes(String(phase.projectId))) {
                        return occupation;
                    }
                }).map((occupation) => occupation.id);

                const members = state.members.filter((member) => {
                    if (occupationIds.includes(Number(member.occupation_id))) {
                        return member;
                    }
                });

                members.forEach((member) => {
                    if (member.team_list.includes(String(state.selectedTeam))) {
                        obj[phase.id] = phase.name;
                    }
                });
                //--------state.selectedTeam が選択済みの時
            }
        }
        return obj
    }, {})
}

//稼働データ作成
function OperationsInfo(state) {
    /*
    インフォメーションボックス(カレントターム)
        計画
        実績
        達成率
    */
    const { selectedOperations, selectedPlans, selectedProgresses } = Preprocessing(state)

    // 稼働予実の予算の計算
    let operationAchievementPlan = {};
    selectedPlans.forEach(plan => {
        Object.keys(plan.planTaskOperationTimes).map(phase_id => {
            if (Object.keys(state.label).includes(phase_id)) {
                Object.keys(plan.planTaskOperationTimes[phase_id]).map(task_id => {
                    operationAchievementPlan[phase_id] = (operationAchievementPlan[phase_id] || 0) + plan.planTaskOperationTimes[phase_id][task_id];
                });
            }
        });
    });

    // 稼働_計画_total
    const totalOperationAchievementPlan = Object.values(operationAchievementPlan).reduce((sum, element) => sum + Number(element), 0);

    // 稼働予実の実績の計算
    let operationAchievementAct = {};

    selectedOperations.forEach(operation => {
        if (Object.keys(state.label).includes(String(operation.phase_id))) {
            operationAchievementAct[operation.phase_id] = (operationAchievementAct[operation.phase_id] || 0) + operation.operation_time;
        }
    });

    // 稼働予実の実績_total
    const totalOperationAchievementAct = Object.values(operationAchievementAct).reduce((sum, element) => sum + Number(element), 0);

    // 稼働予実の達成率_total
    let totalOperationAchievementRate = ((totalOperationAchievementAct / totalOperationAchievementPlan) * 100).toFixed(2)
    if (isNaN(totalOperationAchievementRate) || !isFinite(totalOperationAchievementRate)) {
        totalOperationAchievementRate = "0"
    }
    state.totalOperation = { "plan": totalOperationAchievementPlan, "result": totalOperationAchievementAct, "complete": totalOperationAchievementRate }
    /*
        グラフ
        達成率
    */

    // 稼働予実の達成率の計算
    let operationAchievementRate = {}; //(稼働予実の実績/稼働予実の予算)
    Object.keys(operationAchievementAct).map((phase_id) => {
        operationAchievementRate[phase_id] = (operationAchievementAct[phase_id] / (operationAchievementPlan[phase_id] | 0) * 100).toFixed(2)
        // 正しい値でない場合 0 に置き換えている
        if (isNaN(operationAchievementRate[phase_id]) || !isFinite(operationAchievementRate[phase_id])) {
            operationAchievementRate[phase_id] = "0"
        }
    })
    Object.keys(state.label).map((phase_id) => {
        if (!operationAchievementRate[phase_id]) {
            operationAchievementRate[phase_id] = "0"
        }
    })
    state.operationAchievementRate = operationAchievementRate
}


function ProgressesInfo(state) {
    /*
    インフォメーションボックス(カレントターム)
        計画
        実績
        達成率
    */
    const { selectedOperations, selectedPlans, selectedProgresses } = Preprocessing(state)

    // フェーズ行動予実（完了数）の予算
    // 行動予実の予算の計算
    let actionAchievementPlan = {};
    selectedPlans.map(plan => {
        Object.keys(plan.planComplete).map(phase_id => {
            if (Object.keys(state.label).includes(phase_id)) {
                actionAchievementPlan[phase_id] = (plan.planComplete[phase_id] || 0) + (actionAchievementPlan[phase_id] || 0)
            }
        });
    });
    // 行動_現時点予算_total
    const totalActionAchievementPlan = Object.values(actionAchievementPlan).reduce((sum, element) => sum + Number(element), 0);

    // フェーズ行動予実（完了数）の実績
    let actionAchievementAct = {};
    selectedProgresses.map(progress => {
        // 完了の数なので1つずつ追加
        if (Object.keys(state.label).includes(String(progress.phase_id))) {
            actionAchievementAct[progress.phase_id] = (actionAchievementAct[progress.phase_id] || 0) + 1;
        }
    });

    // 行動_実績_total
    const totalActionAchievementAct = Object.values(actionAchievementAct).reduce((sum, element) => sum + Number(element), 0);

    // 行動_達成率_total
    let totalActionAchievementRate = ((totalActionAchievementAct / totalActionAchievementPlan) * 100).toFixed(2)
    // 正しい値でない場合 0 に置き換えている
    if (isNaN(totalActionAchievementRate) || !isFinite(totalActionAchievementRate)) {
        totalActionAchievementRate = "0"
    }
    state.totalProgress = { "plan": totalActionAchievementPlan, "result": totalActionAchievementAct, "complete": totalActionAchievementRate }

    // 行動_達成率 グラフ用
    let actionAchievementRate = {}; //(行動予実_実績/行動予実_予算)
    Object.keys(actionAchievementAct).map((phase_id) => {
        actionAchievementRate[phase_id] = (actionAchievementAct[phase_id] / (actionAchievementPlan[phase_id] | 0) * 100).toFixed(2)
        // 正しい値でない場合 0 に置き換えている
        if (isNaN(actionAchievementRate[phase_id]) || !isFinite(actionAchievementRate[phase_id])) {
            actionAchievementRate[phase_id] = "0"
        }
    })

    Object.keys(state.label).map((phase_id) => {
        if (!actionAchievementRate[phase_id]) {
            actionAchievementRate[phase_id] = "0"
        }
    })
    state.actionAchievementRate = actionAchievementRate
}
