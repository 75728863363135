'use strict';
const sideMenuInitialState = {
    // state: "init"
    state: "close"
}
export function sideMenu(state = sideMenuInitialState, action) {
    // console.log(action);
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'UPDATE_SIDE_MENU':
            newState.state = action.payload.value;
            break;
        default:
            return state;
    }
    return newState;
};
