'use strict';
const initialState = {
    username: "",
    role: "",
    companyId: "",
    mail: "",
    id: "",
    name: "",
    kana: "",
    pageModeRole: {},
    memberRole: []
}
export function authUser(state = initialState, action) {
    console.log(action);
    console.log(state);
    const newState = Object.assign({}, state);
    switch (action.type) {
        case 'AUTH':
            newState.username = action.payload.userInfo.username;
            newState.role = action.payload.userInfo.role;
            newState.companyId = action.payload.userInfo.companyId;
            newState.mail = action.payload.userInfo.mail;
            newState.id = action.payload.userInfo.id;
            newState.name = action.payload.userInfo.name;
            newState.kana = action.payload.userInfo.kana;
            newState.memberRole = action.payload.userInfo.memberRole ? action.payload.userInfo.memberRole : [];
            newState.pageModeRole = action.payload.userInfo.pageModeRole;
            break;
        default:
            return state;
    }
    return newState;
};
