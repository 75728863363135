'use strict';
const loadingInitialState = {
    state: "flex"
}
export function loading(state = loadingInitialState, action) {
    // console.log(action);
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'UPDATE_LOADING':
            (action.payload.value == "hide") ? newState.state = "none" : newState.state = "flex";
            break;
        default:
            return state;
    }
    return newState;
};
