'use strict';
import React from 'react';

export default function Controller(props) {
    console.log("Controller",props);
    const selectCtlTeamsProps = ({conditions, teams, onChangeSelectCtlTeams}) =>
                                ({conditions, teams, onChangeSelectCtlTeams})
    const selectCtlMembersProps = ({conditions, teams, members, onChangeSelectCtlMembers}) =>
                                  ({conditions, teams, members, onChangeSelectCtlMembers})
    const selectCtlProjectsProps = ({conditions, projects, members, occupations, onChangeSelectCtlProjects}) =>
                                   ({conditions, projects, members, occupations, onChangeSelectCtlProjects})
    const selectCtlPhasesProps = ({conditions, phases, onChangeSelectCtlPhases}) =>
                                 ({conditions, phases, onChangeSelectCtlPhases})
    const isDisable = false

    console.log("isDisable:", isDisable)
    return (
                <div className="row no-gutters h-100 ml-4">
                    <div className="form-row">
                        <div className="col">
                            <SelectCtlTeams {...selectCtlTeamsProps(props)} />
                        </div>
                        <div className="col pl-3">
                            <SelectCtlMembers {...selectCtlMembersProps(props)} />
                        </div>
                        <div className="col pl-3">
                            <SelectCtlProjects {...selectCtlProjectsProps(props)} />
                        </div>
                        <div className="col pl-3">
                            <SelectCtlPhases {...selectCtlPhasesProps(props)} />
                        </div>
                    </div>
                </div>

    )
}

function SelectCtlTeams(props) {
    const options = props.teams.list.map((team, index) =>
        <option key={index} value={team.id} >{team.name}</option>
    );
    const value = props.conditions.selectedTeam
    return (
        <div className="form-group">
            <div><label htmlFor="SelectCtlTeams" className="col-form-label">チーム</label></div>
            <div className="retool-select-wrap retool-select-wrap-default">
                <select id="SelectCtlTeams"
                       className="form-control retool-form-select-wide"
                       value={value}
                       onChange={(e) => props.onChangeSelectCtlTeams(e)} >
                       <option value="-2">選択してください</option>
                       <option value="-1">全チーム</option>
                    {options}
                </select>
            </div>
        </div>
    )
}

function SelectCtlMembers(props) {
    const team = props.teams.list.find(team => team.id == props.conditions.selectedTeam)
    
    const options = team && props.members.list.filter((member, index) => 
        member.team_list.includes(String(team.id)))
            .map((member, index) => <option key={index} value={member.id} >{member.name}</option>)
            
    const value = props.conditions.selectedMember

    return (
        <div className="form-group">
            <div><label htmlFor="SelectCtlMembers" className="col-form-label">担当</label></div>
            <div className="retool-select-wrap retool-select-wrap-default">
                <select id="SelectCtlMembers"
                       className="form-control retool-form-select"
                       value={value}
                       onChange={(e) => props.onChangeSelectCtlMembers(e)} >
                    <option value="-1">全担当</option>
                    {options}
                </select>
            </div>
        </div>
    )
}

function SelectCtlProjects(props) {
    console.log("SelectCtlProjects", props)

    let options = props.projects.list.map((project, index) =>
        <option key={index} value={project.id} data-value={JSON.stringify(project)}>{project.name}</option>
    );
    // チームが選択済みならプロジェクトをチームidでフィルタリング
    if (props.conditions.selectedTeam != -1) {
        const sort_project = []

        const occupationIds = props.members.list.filter(member => {
            return member.team_list.includes(String(props.conditions.selectedTeam))
        }).map(m => m.occupation_id)

        const projectLists = props.occupations.list.filter(occupation => {
            if(occupationIds.includes(String(occupation.id))){
                return occupation;
            }
        }).map(occupation => occupation.project_list)

        props.projects.list.forEach(project => {
            projectLists.forEach(projectList => {
                if(projectList.includes(String(project.id))){
                    sort_project.push(project)
                }
            })
        })
        options = sort_project.map((project, index) =>
            <option key={index} value={project.id} data-value={JSON.stringify(project)}>{project.name}</option>
        );
    }
    
    const value = props.conditions.selectedProject
    return (
        <div className="form-group">
            <div><label htmlFor="selectCtlProjects" className="col-form-label">プロジェクト</label></div>
            <div className="retool-select-wrap retool-select-wrap-default">
                <select id="selectCtlProjects"
                        className="form-control retool-form-select"
                        value={value}
                        onChange={(e) => props.onChangeSelectCtlProjects(e)} >
                     <option value="-1">全プロジェクト</option>
                     {options}
                </select>
            </div>
        </div>
    )
}


function SelectCtlPhases(props) {
    
    const options = props.phases.list.filter((phase) => phase.projectId == props.conditions.selectedProject || props.conditions.selectedProject == -1)
        .map((phase, index) => {
            return (
                <option key={index} value={phase.id} >{phase.name}</option>
            )
    })
    
    const value = props.conditions.selectedPhase

    return (
        <div className="form-group">
            <div><label htmlFor="selectCtlPhases" className="col-form-label">フェーズ</label></div>
            <div className="retool-select-wrap retool-select-wrap-default">
                <select id="selectCtlPhases"
                        className="form-control retool-form-select"
                        value={value}
                        onChange={(e) => props.onChangeSelectCtlPhases(e)} >
                    <option value="-1">全フェーズ</option>
                    {options}
                </select>
            </div>
        </div>
    )
}

