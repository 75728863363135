import * as env from '@setEnv';


const AmazonCognitoIdentity = require('amazon-cognito-identity-js');


class CognitoUserPoolAuth {
    constructor() {
        const poolData = {
            UserPoolId : env.USER_POOL_ID,
            ClientId : env.CLIENT_ID
        };
        this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    }

    login(userName, password, callback) {

        const cognitoUser = this.getCognitoUser(userName);
        const authenticationDetails = this.createAuthenticationDetails(userName, password);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                console.log("onSuccess");
                cognitoUser.cacheTokens();
                location.href = "index.html"
            },
            onFailure: function (err) {
                console.error("onFailure");
                if (callback) callback(err);
            },
            mfaRequired: function (codeDeliveryDetails) {
                console.log("mfaRequired");
                console.log(codeDeliveryDetails);
            },
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                console.log("newPasswordRequired");
            }
        });
    }
    createAuthenticationDetails(userName, password) {
        const authenticationData = {
            Username: userName,
            Password: password
        };
        return new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    }

    signOut() {
        const currentUser = this.currentUser();
        if (!currentUser) return;
        const cognitoUser = this.getCognitoUser(currentUser.username);
        if (!cognitoUser) return;
        cognitoUser.signOut();
        location.href = '/login.html'
    }

    getCognitoUser(userName) {
        const userData = {
            Username : userName,
            Pool : this.userPool
        };
        return new AmazonCognitoIdentity.CognitoUser(userData);
    }

    currentUser() {
        let cognitoUser = this.userPool.getCurrentUser()
        if (cognitoUser == null) {
            location.href = '/login.html?message=' + '再ログインしてください';
        }
        return cognitoUser;
    }

    getUserInfo() {
        const payload = this.getSession().getIdToken().payload;
        console.log(payload)
        return {
            username: payload["cognito:username"],
            role: payload["custom:role"],
            companyId: payload["custom:companyId"]
        }
    }

    getToken() {
        return this.getSession().getIdToken().getJwtToken();
    }

    getSession() {
        let current_user = this.currentUser();
        console.log(current_user);

        let session = current_user.getSession(function (err, session) {
            console.log(session);
            if (session) {
                return session;
            }
            if (err) {
                console.log(err);
            }
        });


        // セッションに情報が入らない時がある（1時間程度操作をしていない時）ので、
        // その際はリロードをすれば読み込みできるので、同じページにリダイレクトを行う。
        // sleepをせずにリダイレクトを行うとエラーとなるので、スリープさせてから実施。
        if (!session) {
            const sleep = msec => new Promise(resolve => setTimeout(resolve, msec));
            (async () => {
                console.log("Warning : Session is not set!!");
                //await sleep(3000);
                await sleep(1000);
                location.href = location.pathname;
            })();
        }
        console.log(session);
        return session;
    }
    getJwtToken() {
        return new Promise((resolve, reject) => {
            const cognitoUser = this.currentUser();
            if (!cognitoUser) {
                return reject("unauthorized");
            }
            cognitoUser.getSession((err, result) => {
                if (err) {
                    return reject(err);
                }
                resolve(result.getIdToken().getJwtToken());
            });
        })
    }


}
export default new CognitoUserPoolAuth();