
import React, { Component } from 'react';

import Header from '@common/components/menu/Header';
import SideMenu from '@common/components/menu/SideMenu';

import authUser from '@common/auth/AuthUser';
import * as masters from '@common/data/masters';
import * as results from '@common/data/results';

import Controller from './components/Controller';
import InformationBox from './components/InformationBox';
import PhaseGraph from './components/PhaseGraph';


class App extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        (async () => {
            const userInfo = authUser.getUserInfo();
            const user = await masters.getUser();
            const pageModeRole = await masters.getClientSetting();
            console.log('componentDidMount:user', user);
            userInfo.mail = user[0].mail;
            userInfo.id = user[0].id;
            userInfo.name = user[0].name;
            userInfo.kana = user[0].kana;
            userInfo.pageModeRole = pageModeRole;
            console.log('componentDidMount:userInfo', userInfo);

            // クライアントログのみ使う人用
            const activePageModeStandard = userInfo.pageModeRole.STANDARD;
            const activePageModeDataProcessing = userInfo.pageModeRole.DATA_PROCESSING;
            const visibleOnlyClientLog = !activePageModeStandard && activePageModeDataProcessing;

            // クライアントログのみ使う人にはいらないデータなので、それ以外の人の時にデータ取得する
            if(!visibleOnlyClientLog){
                const mainTermType = await masters.getMainTermType();
                const terms = await masters.getTerms(mainTermType);
                const currentTerm = terms.find((term) => term.id == 0).current_id
                const members = await masters.getMembers();
                const teams = masters.filterByMemberRoles(await masters.getTeams(), user[0].role, user[0].id, members)
                //--データモデルが変更されたため
                let occupations = [];
                await Promise.all(members.map(async (member) => {
                    if (member.occupation_id) {
                        occupations.push(await masters.getOccupations(member.occupation_id))
                    }
                }));
                occupations = occupations.flat().reduce((lists, occupation) => {
                    const isExist = lists.find((list) => list.id == occupation.id);
                    if (!isExist) {
                        lists.push(occupation);
                    }
                    return lists;
                }, []);
                const projects = masters.filterByMemberRolesByProjects(await masters.getProjects2(), user[0].role, occupations)
                let phases = await Promise.all(projects.map(async (project) => {
                    const phases = await masters.getPhase2(project.id)
                    project["phaseIds"] = phases.map(phase => phase.id)
                    phases.forEach(phase => {
                        phase["projectId"] = project.id
                    })
                    return phases
                }))
                phases = phases.flat()
                this.props.didMnt(currentTerm, terms, members, teams, projects, phases, occupations);
            }

            //--データモデルが変更されたため
            this.props.auth(userInfo);
            // ローディング解除
            this.props.updateLoading("hide");
        })();
    }

    //-- Controller
    onChangeSelectCtlTeams = (e) => {
        (async () => {
            const value = e.target.value
            //選択して下さいのとき
            if (value != "-2") {
                let selectedTeamMembers = []
                if (value == "-1") {
                    selectedTeamMembers = this.props.members.list.map(member => member.id)
                } else {
                    selectedTeamMembers = this.props.members.list.filter(member => member.team_list.includes(value)).map((member => member.id))
                }
                // ローディング表示
                this.props.updateLoading("show");
                // -- 移動
                let plans = (await Promise.all(
                    selectedTeamMembers.map(async (member) => (
                        (await results.getPlansPerTerm(member, this.props.terms.currentTerm)
                            .catch(e => {
                                if (e == results.ERRORS.NOT_FOUND) return []
                                throw Error(e);
                            }))
                    )
                    )
                )).filter((plan) => {
                    if (plan.length) {
                        return plan
                    }
                }).reduce((list, plan) => {
                    list.push(plan[0])
                    return list
                }, []).sort((a, b) => a.member_id - b.member_id)

                // 当月の文字列を作る => 202202
                const date = this.props.terms.list.find(term => term.id == this.props.terms.currentTerm)

                const currentMonth = `${date.from.split("/")[0]}${date.from.split("/")[1]}`

                const operations = (await Promise.all(
                    selectedTeamMembers.map(async (targetMember) => await results.getOperationsPerDay(targetMember, currentMonth))
                )).reduce((arr, operations) => {
                    arr.push(...operations)
                    return arr
                }, []);

                const items = await results.getItems();
                // 各プロジェクトの最終フェーズindex一覧作成
                const finalPhases = this.props.projects.list.map(project => {
                    // フェーズのindexが大きいもの
                    const phases = this.props.phases.list.filter(phase => project.phaseIds.includes(phase.id))
                    const finalPhase = phases.sort((a, b) => b.order - a.order) // orderの降順
                    return finalPhase[0]
                    // project.phases.reduce((finalPhase, phase) => {
                    //     if (finalPhase.index < phase.index) return {...phase, "project_id": project.id}
                    //     return finalPhase
                    // }, {index: -1})
                });
                //該当プロジェクトの最終ステータスが完了の場合のプログレスを返す。
                const progresses = []
                items.forEach(item => {
                    // 各プロジェクトの最終フェーズindex一覧から該当プロジェクトの最終フェーズindexを取得
                    const finalPhase = finalPhases.find(finalPhase => finalPhase.project_id == item.project_id)
                    // 最終プログレスを取得
                    const finalProgress = finalPhase && item.progresses.find(progress => progress.index == finalPhase.index)
                    // 最終プログレスを取得が完了（3）の場合
                    if (finalProgress && finalProgress.status == 3) {
                        // 当月のみの成果情報を返す。
                        // これだと途中実績が評価されないのか？？
                        if (finalProgress.start_date.slice(0, 6) <= currentMonth && currentMonth <= finalProgress.end_date.slice(0, 6)) {
                            item.progresses.forEach((progress) => {
                                progresses.push(progress)
                            })
                        }
                    }
                });
                this.props.updateSelectCtlTeams(Number(value), plans, operations, progresses)
                // ローディング解除
                this.props.updateLoading("hide");
            }
        })()
    }
    onChangeSelectCtlMembers = (e) => {
        this.props.updateSelectCtlMembers(e.target.value)
    }
    onChangeSelectCtlProjects = (e) => {
        this.props.updateSelectCtlProjects(e.target.value)
    }
    onChangeSelectCtlPhases = (e) => {
        this.props.updateSelectCtlPhases(e.target.value)
    }

    onChangeSideMenu = (value) => {
        this.props.updateSideMenu(value);
    }
    // dom1のアイコンごとにハンドラ
    onClickDashboard = (value) => {
        this.props.updateSideMenu(value);
    }
    onClickResult = (value) => {
        this.props.updateSideMenu(value);
    }
    onClickActivity = (value) => {
        this.props.updateSideMenu(value);
    }
    onClickPredict = (value) => {
        this.props.updateSideMenu(value);
    }
    onClickReport = (value) => {
        this.props.updateSideMenu(value);
    }
    onClickMaster = (value) => {
        this.props.updateSideMenu(value);
    }
    //コントローラの縮小イベント
    onClickInvisibleControllerBody = (value) => {
        this.props.updateControllerBody(value);
    }
    render() {
        console.log('App.render', this.props);
        // 82 + 54（コントローラ部分の高さ） = 136
        const height = this.props.conditions.isOpen ? window.innerHeight - 238 : window.innerHeight - 136;
        // console.log(window.innerHeight);

        const headerData = ({ authUser: userInfo }) => ({ userInfo })
        //const sideMenuData = ({authUser:userInfo}) => ({userInfo})
        const sideMenuData = ({ sideMenu, authUser: userInfo }) => ({ sideMenu, userInfo })
        const sideMenuHandler = ({ onChangeSideMenu, onClickDashboard, onClickResult, onClickActivity, onClickReport, onClickPredict, onClickMaster }) =>
            ({ onChangeSideMenu, onClickDashboard, onClickResult, onClickActivity, onClickReport, onClickPredict, onClickMaster })


        const controllerData = ({ terms, members, teams, projects, phases, conditions, occupations }) =>
            ({ terms, members, teams, projects, phases, conditions, occupations })
        const controllerHandler = ({ onChangeSelectCtlTeams, onChangeSelectCtlMembers, onChangeSelectCtlProjects, onChangeSelectCtlPhases }) =>
            ({ onChangeSelectCtlTeams, onChangeSelectCtlMembers, onChangeSelectCtlProjects, onChangeSelectCtlPhases })

        const informationBoxData = ({ summary }) => ({ summary })
        const phaseGraphData = ({ members, conditions, summary }) => ({ members, conditions, summary })

        // サイドメニュー開閉時のヘッダーの挙動 //
        const sideOpen = (this.props.sideMenu.state === 'open') && "retool-header-side-open";
        const sideClosing = (this.props.sideMenu.state === 'closing') && "retool-header-side-closing";
        const sideClose = (this.props.sideMenu.state === 'close') && "retool-header-side-close";

        // サイドメニュー開閉時のコンテンツの挙動 //
        const open = (this.props.sideMenu.state === 'open') && "retool-contents-side-open";
        const closing = (this.props.sideMenu.state === 'closing') && "retool-contents-side-closing";
        const close = (this.props.sideMenu.state === 'close') && "retool-contents-side-close";

        // クライアントログのみ使う人用
        const activePageModeStandard = this.props.authUser.pageModeRole.STANDARD;
        const activePageModeDataProcessing = this.props.authUser.pageModeRole.DATA_PROCESSING;
        const visibleOnlyClientLog = !activePageModeStandard && activePageModeDataProcessing;

        // クライアントログのみ使う人にはいらないデータなので、それ以外の人の時にデータ取得する
        const viewState = visibleOnlyClientLog ? "none " : ""
        return (
            <>
                <div id="loading" style={{ display: this.props.loading.state }}>
                    <div className="spinner"></div>
                </div>
                <SideMenu {...sideMenuData(this.props)} {...sideMenuHandler(this)} />
                <div className={sideOpen || sideClosing || sideClose} >
                    <Header {...headerData(this.props)} />
                </div>
                <div className="small row retool-main" id="retoolMainComponents" >
                    <div className={open || closing || close}>
                        <div className="card shadow-sm my-2">
                            <div className="card-header retool-contents-title-card-header retool-contents-title-default">
                                <div className="row no-gutters">
                                    <div className="col">
                                        <h6>
                                            <button type="button"
                                                className="btn retool-btn-controller-body  retool-btn-controller-body-default"
                                                data-toggle="collapse"
                                                data-target=".contorllerBody"
                                                aria-expanded="true"
                                            >
                                                <span className="retool-contents-title-bullet-default retool-contents-title">▼</span>
                                            </button>
                                            ダッシュボード
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body retool-contents-body-default pt-2" style={{ display: viewState }}>
                                <div className="contorllerBody retool-contorllerBody-duration  collapse show">
                                    <Controller {...controllerData(this.props)} {...controllerHandler(this)} />
                                </div>
                                <div className="row mt-4">
                                    <div className="col-sm-12">
                                        <InformationBox {...informationBoxData(this.props)} />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-sm-12">
                                        <PhaseGraph {...phaseGraphData(this.props)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default App;
