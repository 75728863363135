'use strict';
import retool_auth from '@common/auth/AuthUser';
import { BASE_DOMAIN, ADMIN_BASE_DOMAIN } from '@setEnv';

export const ERRORS = {
    "INVALID_TOKEN": "invalid token.",
    "UNAUTHORIZED": "unauthorized.",
    "NOT_FOUND": "not found.",
    "INTERNAL_SERVER_ERROR": Error('internal server error.'),
    "BAD_GATEWAY": Error('bad gateway.'),
    "UNHANDLED_ERROR": Error('unhandled error.')
}
const handleErrors = (res) => {
    console.log("********************")
    console.log(res)
    console.log("********************")

  if (res.ok) {
    return res;
  }

    switch (res.status) {
        case 400: throw ERRORS.INVALID_TOKEN;
        case 401: throw ERRORS.UNAUTHORIZED;
        case 404: throw ERRORS.NOT_FOUND;
        case 500: throw ERRORS.INTERNAL_SERVER_ERROR;
        case 502: throw ERRORS.BAD_GATEWAY;
        default: throw ERRORS.UNHANDLED_ERROR;
    }

};

/**
 *
 */
export async function getSequences(key) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/sequences`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "id": key
        })
    }
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => {
            console.log(JSON.stringify(json));
            return Reflect.get(json, "data")
        });
    //    return {sequence: 999}
}

/**
 *
 */
export async function getMembers() {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/members`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    //    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}
//export async function addMembers(items) {
//    //TODO: slice items / 25
//    const id = retool_auth.getToken();
//    const {companyId} = retool_auth.getUserInfo();
//    const URL = `${BASE_DOMAIN}/${companyId}/members`;
//    const OPTIONS = {
//        method: "POST",
//        mode: "cors",
//        cache: "no-cache",
//        headers: {
//         "Authorization": id,
//         "Content-Type": "application/json"
//        },
//        body: JSON.stringify({
//            "items": items
//        })
//    }
//    console.log(OPTIONS);
//    return await fetch(URL, OPTIONS)
//        .then(res => res.json())
//        .then(json => Reflect.get(json, "data"));
//}

export async function signupMember(email, password) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const postData = {
        "username": email,
        "email": email,
        "password": password
    };
    const URL = `${ADMIN_BASE_DOMAIN}/${companyId}/admin/create_user`;
    // const URL = `https://admin.dev.re-tool.jp/${companyId}/admin/create_user`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(postData)
    }
    console.log(URL);
    console.log(OPTIONS);
    // return await fetch(URL, OPTIONS)
    //     .then(res => res.json());
    return await fetch(URL, OPTIONS)
        .catch((e) => { throw Error(e); })
        .then(handleErrors)
        .then(res => res.json());


}
export async function getUser() {
    const id = retool_auth.getToken();
    const { companyId, username } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/members/mail/${username}`;
    //    const URL = `https://hu802eqlth.execute-api.ap-southeast-2.amazonaws.com/v1/${companyId}/members/${username}`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    console.log(URL);
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}

export async function putMember(item) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/members/mails/${item.mail}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item
        })
    }
    console.log(URL);
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}

export async function getOccupations(occupationId) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/occupations/id/${occupationId}`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"))
        .catch((e) => { return [] })
}
export async function putOccupations(item) {
    const id = retool_auth.getToken();
    const { companyId, username } = retool_auth.getUserInfo();
    const occupationId = item.id
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/occupations/id/${occupationId}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item
        })
    }
    console.log(URL);
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}
/*
 master2---
 */
export async function getProjects2() {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/projects/list`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => {
            return Reflect.get(json, "data")
        });
}
export async function getProject2(item) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/projects/id/${item.id}`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => {
            return Reflect.get(json, "data")
        });
}
export async function putProject2(item) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const projectId = item.id
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/projects/id/${projectId}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item
        })
    }
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}

export async function getPhases2() {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/phases/list`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}

export async function getPhase2(projectId) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/phases/project/${projectId}/list`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"))
        .catch((e) => { return [] })

}

export async function putPhase2(item) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const projectId = item.projectId
    const phaseId = item.id
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/phases/project/${projectId}/phase/${phaseId}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item
        })
    }
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}

export async function getTasks2(phaseId) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/tasks/phase/${phaseId}/list`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}

export async function putTask2(task) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const phaseId = task.phaseId
    const taskId = task.id
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/tasks/phase/${phaseId}/task/${taskId}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": task
        })
    }
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}

export async function getTermTypes() {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/reportTermTypes `;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    //    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .catch((e) => { throw Error(e); })
        .then(res => res.json())
        .then(json => {
            console.log(JSON.stringify(json));
            return Reflect.get(json, "data")
        });

}

/*
 ---master2
 */

export async function getProjects() {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/projects`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    //    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => {
            console.log(JSON.stringify(json));
            return Reflect.get(json, "data")
        });

}
export async function putProject(item) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/projects/${item.id}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item
        })
    }
    console.log(URL);
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}

/**
 *
 */
export async function getTeams() {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/teams/list`;
    // const URL = `${BASE_DOMAIN}/${companyId}/masters/teams`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    //    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}
export async function getTeam(teamId) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/teams/id/${teamId}`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    //    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}
export async function putTeam(item) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/teams/id/${item.id}`;
    // const URL = `${BASE_DOMAIN}/${companyId}/masters/teams/${item.id}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item
        })
    }
    console.log(URL);
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}

/**
 *
 */
export async function getPhaseTasks() {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/phasetasks`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    //    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}

export async function putPhaseTask(item) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/phasetasks/${item.id}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item
        })
    }
    console.log(URL);
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}




export async function getTasks(teamId) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/tasks/${teamId}`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    //    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"))
        .catch((e) => { return [] })
}

export async function putTask(item) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/tasks/${item.team_id}/${item.id}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item
        })
    }
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}

export async function getTerms(type) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/terms/${type}`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    //    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}
export async function getMainTermType() {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/mainTermType`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    //    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => json["data"]["MAIN_TERM_TYPE"]);
    // .then(json => Reflect.get(json, "data"));
}
export async function getCustomers() {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/customers`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }

    //    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => {
            // console.log(JSON.stringify(json));
            return Reflect.get(json, "data")
        })
        .catch((e) => { return [] })

}
export async function putCustomer(item) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/customers/${item.id}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item
        })
    }
    console.log(URL);
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}



export async function getCustomerAttributes() {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/customerAttributes`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }

    //    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => {
            // console.log(JSON.stringify(json));
            return Reflect.get(json, "data")
        })
        .catch((e) => { return [] })


}
export async function putCustomerAttribute(item) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/customerAttributes/${item.id}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item
        })
    }
    console.log(URL);
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}


export async function getCommodities() {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/commodities`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }

    //    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => {
            // console.log(JSON.stringify(json));
            return Reflect.get(json, "data")
        })
        .catch((e) => { return [] })


}

export async function putCommodity(item) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/commodities/${item.id}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item
        })
    }
    // console.log(URL);
    // console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}


export async function getCommodityAttributes() {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/commodityAttributes`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }

    //    return await fetch(URL)
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => {
            // console.log(JSON.stringify(json));
            return Reflect.get(json, "data")
        })
        .catch((e) => { return [] })


}
export async function putCommodityAttribute(item) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/commodityAttributes/${item.id}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item
        })
    }
    console.log(URL);
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => Reflect.get(json, "data"));
}

export const filterByCurrentTerm = (targets, currentTerm) => (
    Object.values(targets.filter((obj) => obj.term >= currentTerm)
        .reduce((tmp, obj) => {
            if (obj.id in tmp) {
                if (tmp[obj.id].term > obj.term) tmp[obj.id] = obj
            } else {
                tmp[obj.id] = obj
            }
            return tmp
        }, {}))
)

export const filterTeamByUser = (teams, userInfo) => (
    teams.filter((team) => team.members.indexOf(userInfo.username) >= 0)
)

export const filterByMemberRoles = (teams, roles, user_id, members) => {
    // return teams
    console.log("@@", roles)
    if (roles.length == 0) {
        // memberRoleが未設定の場合
        return teams// => 開発チーム　セールスチーム
    } else {
        console.log("filterByMemberRoles")
        // // ログインメンバがteamに所属していて、team_read_flgが未設定の修正
        // teams.forEach((team) => {
        //     if(Object.keys(team.member_details).includes(user_id)){
        //         const role = roles.find(role => role.team_id == team.id)
        //         if(!role){
        //             roles.push({"team_id": String(team.id), "team_read_flg": false})
        //         }
        //     }
        // })

        const filterTeams = teams.reduce((arr, team) => {
        // return teams.reduce((arr, team) => {
            const role = roles.find(role => role.team_id == team.id)
            if (role && role.team_read_flg) {
                // 所属していてteam_read_flgがtrueの時
                console.log("所属していてteam_read_flgがtrueの時")
                arr.push(team)
            }
            else if (role && !role.team_read_flg) {
                // 所属していてteam_read_flgがfalseの時
                // 所属チーム内のメンバを以外を除く
                members.forEach((member) => {
                    if (member.id != user_id) {
                        member.team_list = []
                    }
                })
            }

            return arr
        }, [])
        return filterTeams // => 開発チーム
    }
}


export const filterByMemberRolesByProjects = (projects, roles, occupations) => {
    if (roles.length == 0) {
        // memberRoleが未設定の場合
        return projects
    } else {
        const teamList = roles.filter(role => role.team_read_flg).map(team => team.team_id)
        // メンバーが持つproject_listに切り替えるだけでは
        return projects.reduce((arr, project) => {
            teamList.forEach((teamId) => {
                console.log('@@', occupations);
                const projectList = occupations.find(occupation => occupation.id == +teamId).project_list
                if (projectList.includes(String(project.id))) {
                    if (!arr.map(v => v.id).includes(project.id)) {
                        arr.push(project)
                    }
                }
            })
            return arr
        }, [])
    }
}

export async function getTuningCustomersFile() {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/analysis/itemsEvaluation/customers`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => {
            console.log(JSON.stringify(json));
            return Reflect.get(json, "data")
        });

}


export async function putTuingCustomerFile(item, memberId) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/analysis/itemsEvaluation/customers/${memberId}`;

    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item
        })
    }
    console.log(URL);
    return await fetch(URL, OPTIONS)
        .then(res => res);
}




export async function getTuningCommoditiesFile() {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/analysis/itemsEvaluation/commodities`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        }
    }
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => {
            console.log(JSON.stringify(json));
            return Reflect.get(json, "data")
        });

}


export async function putTuingCommoditiesFile(item, memberId) {
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters/analysis/itemsEvaluation/commodities/${memberId}`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "item": item
        })
    }
    console.log("@@", URL);
    console.log("@@", OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res);
}


// パスワード変更
export async function putChangePassword(oldPassWord, newPassWord, accessToken) {
    console.log("oldPassWord, newPassWord, accessToken", oldPassWord, newPassWord, accessToken)
    const { companyId } = retool_auth.getUserInfo();
    const postData = {
        "oldPassword": oldPassWord,
        "newPassword": newPassWord,
        "accessToken": accessToken
    };
    const URL = `${ADMIN_BASE_DOMAIN}/${companyId}/admin/change_password`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(postData)
    }
    console.log(URL);
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .then(res => res);
}

// 有効/無効更新
export async function putDisableMember(member_id) {
    console.log("username", member_id)
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const postData = {
        "member_id": member_id
    };
    const URL = `${ADMIN_BASE_DOMAIN}/${companyId}/admin/${member_id}/disable_user`;
    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(postData)
    }
    console.log(URL);
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .catch((e) => { throw Error(e); })
        .then(handleErrors)
        .then(res => res);
}


export async function putEnableMember(member_id) {
    console.log("username", member_id)
    const id = retool_auth.getToken();
    const { companyId } = retool_auth.getUserInfo();
    const postData = {
        "member_id": member_id
    };
    const URL = `${ADMIN_BASE_DOMAIN}/${companyId}/admin/${member_id}/enable_user`;

    const OPTIONS = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Authorization": id,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(postData)
    }
    console.log(URL);
    console.log(OPTIONS);
    return await fetch(URL, OPTIONS)
        .catch((e) => { throw Error(e); })
        .then(handleErrors)
        .then(res => res);
}
// ---

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
/////////////////////masters2///////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////


// /{clientId}/masters2/setting - GET
// クライアントログを使わない人、クライアントログのみ使う人、両方使う人の３パターンに合わせてサイドメニューを切り替えるためのAPI
export async function getClientSetting() {
    const id = retool_auth.getToken();
    const {companyId} = retool_auth.getUserInfo();
    const URL = `${BASE_DOMAIN}/${companyId}/masters2/setting`;
    const OPTIONS = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
         "Authorization": id,
         "Content-Type": "application/json"
        }
    }
    return await fetch(URL, OPTIONS)
        .then(res => res.json())
        .then(json => json["data"]["DISPLAY_MENU"]);
}
