'use strict';
import React, { useState, useEffect } from 'react';

export default function SideMenu(props) {
    console.log('sidemenu', props);
    const [componentMaxHeight, setComponentMaxHeight] = useState(undefined);

    const path = location.href.split('/');
    const category = path ? path[path.length - 2] : ""
    const page = path ? path[2] : ""
    const param = path ? path[3] : ""
    const isMenuOpen = props.sideMenu.state == "open";

    const canVisibleMasters = props.userInfo.role == "admin";
    const canVisibleStandard = props.userInfo.pageModeRole.STANDARD
    const canVisibleClientLog = props.userInfo.pageModeRole.DATA_PROCESSING

    let btnStateToggle;
    if(props.sideMenu.state != "close" ){
        btnStateToggle = props.sideMenu.state == "closing" ? "open" : "closing"
    }else{
        btnStateToggle = "open"
    }

    useEffect(() => {
        const windowHeight = window.innerHeight;

        const bottomComponent = document.getElementById("retoolMainComponents");
        const height = Math.round(bottomComponent.getBoundingClientRect().bottom) + window.pageYOffset;

        if (height < windowHeight){
            setComponentMaxHeight(windowHeight);
        } else {
            setComponentMaxHeight(height);
        }
    });

    const sideMenu = (<div className="retool-sidemenu-default">
                            <div className="retool-menu-item">
                                <div>
                                    <p className="retool-menu-title"></p>
                                </div>
                            </div>
                        {canVisibleStandard &&
                         <>
                            <div className="retool-menu-item">
                                <a href="/dashboard/index.html" className="btn btn-light btn-block text-left rounded-0 retool-menu-btn retool-menu-btn-default" type="button">
                                    <p className="retool-menu-title">ダッシュボード</p>
                                </a>
                            </div>
                            <div className="retool-menu-item">
                                <button className="btn btn-light btn-block text-left rounded-0 retool-menu-btn retool-menu-btn-default" type="button" data-toggle="collapse" data-target={isMenuOpen && ".results-menu"} aria-expanded={category == "results"} >
                                        <p className="retool-menu-title">実績入力</p>
                                </button>
                                <div id="resultsMenu" className={`${category == "results" ? "collapse show" : "collapse"} results-menu`}>
                                    <div className="card-body p-0">
                                        <div className="list-group retool-menu-sub-title">
                                            <a href="/results/operations.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 retool-bg-black"><p className="retool-menu-text">稼働入力</p></a>
                                            <a href="/results/progresses.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 border-bottom-0 retool-bg-black"><p className="retool-menu-text">進捗入力</p></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="retool-menu-item">
                                <button className="btn btn-light btn-block text-left rounded-0 retool-menu-btn retool-menu-btn-default" type="button" data-toggle="collapse" data-target={isMenuOpen && ".activities-menu"} aria-expanded={category == "activities"}>
                                        <p className="retool-menu-title">活動管理</p>
                                </button>
                                <div id="activitiesMenu" className={ `${category == "activities" ? "collapse show" : "collapse"} activities-menu`}>
                                    <div className="card-body p-0">
                                        <div className="list-group retool-menu-sub-title">
                                            <a href="/activities/plans.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 retool-bg-black"><p className="retool-menu-text">活動計画</p></a>
                                            <a href="/activities/achievements.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 border-bottom-0 retool-bg-black"><p className="retool-menu-text">リアルタイム進捗</p></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="retool-menu-item">
                                <button className="btn btn-light btn-block text-left rounded-0 retool-menu-btn retool-menu-btn-default" type="button" data-toggle="collapse" data-target={isMenuOpen && ".predicts-menu"} aria-expanded={category == "predicts"}>
                                        <p className="retool-menu-title">受注分析</p>
                                </button>
                                <div id="predictsMenu" className={ `${category == "predicts" ? "collapse show" : "collapse"} predicts-menu`}>
                                    <div className="card-body p-0">
                                        <div className="list-group retool-menu-sub-title">
                                            <a href="/predicts/predict_customers_tuning.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 retool-bg-black"><p className="retool-menu-text">顧客スコア設定</p></a>
                                            <a href="/predicts/predict_customers_evaluate.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 border-bottom-0 retool-bg-black"><p className="retool-menu-text">顧客スコア分析</p></a>
                                            <a href="/predicts/predict_commodities_tuning.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 retool-bg-black"><p className="retool-menu-text">商材スコア設定</p></a>
                                            <a href="/predicts/predict_commodities_evaluate.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 border-bottom-0 retool-bg-black"><p className="retool-menu-text">商材スコア分析</p></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="retool-menu-item">
                                <button className="btn btn-light btn-block text-left rounded-0 retool-menu-btn retool-menu-btn-default" type="button" data-toggle="collapse" data-target={isMenuOpen && ".reports-menu"} aria-expanded={category == "reports"}>
                                        <p className="retool-menu-title">活動分析</p>
                                </button>

                                <div className={ `${category == "reports" ? "collapse show" : "collapse"} reports-menu`}>
                                        <button className="btn btn-light btn-block text-left rounded-0 retool-menu-btn retool-menu-btn-default" type="button">
                                            <a href="/reports/core_task_rate.html" className="retool-menu-link-defalult rounded-0 border-bottom-0"><p className="retool-menu-title">コアタスク比率</p></a>
                                        </button>

                                        <button className="btn btn-light btn-block text-left rounded-0 retool-menu-btn retool-menu-btn-default pt-1 mt-0" type="button" data-toggle="collapse" data-target={".reports-menu-operation-forecast"}>
                                                <p className="retool-menu-title">稼働データ</p>
                                        </button>
                                        <div className="collapse reports-menu-operation-forecast list-group retool-menu-sub-title">
                                            <a href="/reports/operation_forecast.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 border-bottom-0 retool-bg-black"><p className="retool-reports-menu-text">期間</p></a>
                                            <a href="/reports/operation_forecast_member_phase.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 border-bottom-0 retool-bg-black"><p className="retool-reports-menu-text">担当xフェーズ</p></a>
                                            <a href="/reports/operation_forecast_member_task.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 border-bottom-0 retool-bg-black"><p className="retool-reports-menu-text">担当xタスク</p></a>
                                        </div>

                                        <button className="btn btn-light btn-block text-left rounded-0 retool-menu-btn retool-menu-btn-default pt-1" type="button" data-toggle="collapse" data-target={".reports-menu-action-forecast"}>
                                                <p className="retool-menu-title">行動データ</p>
                                        </button>
                                        <div className="collapse reports-menu-action-forecast list-group retool-menu-sub-title">
                                            <a href="/reports/action_forecast.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 border-bottom-0 retool-bg-black"><p className="retool-reports-menu-text">期間</p></a>
                                            <a href="/reports/action_forecast_member_phase.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 border-bottom-0 retool-bg-black"><p className="retool-reports-menu-text">担当xフェーズ</p></a>
                                        </div>

                                        <button className="btn btn-light btn-block text-left rounded-0 retool-menu-btn retool-menu-btn-default pt-1" type="button" data-toggle="collapse" data-target={".reports-menu-progress-forecast"}>
                                                <p className="retool-menu-title">進捗データ</p>
                                        </button>
                                        <div className="collapse reports-menu-progress-forecast list-group retool-menu-sub-title">
                                            <a href="/reports/progress_forecast.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 border-bottom-0 retool-bg-black"><p className="retool-reports-menu-text">期間</p></a>
                                            <a href="/reports/progress_forecast_member_phase.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 border-bottom-0 retool-bg-black"><p className="retool-reports-menu-text">担当xフェーズ</p></a>
                                        </div>

                                        <button className="btn btn-light btn-block text-left rounded-0 retool-menu-btn retool-menu-btn-default pt-1" type="button" data-toggle="collapse" data-target={".reports-menu-time-efficiency"}>
                                                <p className="retool-menu-title">時間効率データ</p>
                                        </button>
                                        <div className="collapse reports-menu-time-efficiency list-group retool-menu-sub-title">
                                            <a href="/reports/time_efficiency.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 border-bottom-0 retool-bg-black"><p className="retool-reports-menu-text">期間</p></a>
                                            <a href="/reports/time_efficiency_member_phase.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 border-bottom-0 retool-bg-black"><p className="retool-reports-menu-text">担当xフェーズ</p></a>
                                        </div>
                                        {/*
                                        <button className="btn btn-light btn-block text-left rounded-0 retool-menu-btn retool-menu-btn-default" type="button">
                                            <a href="/reports/scatter_plot.html" className="retool-menu-link-defalult rounded-0 border-bottom-0"><p className="retool-menu-title">複合データ分析</p></a>
                                        </button>
                                        */}
                                    </div>
                            </div>
                        </>
                        }

                            {/* <div className="retool-menu-item">
                                <a href="/clientLogLabeling/index.html" className="btn btn-light btn-block text-left rounded-0 retool-menu-btn retool-menu-btn-default" type="button">
                                    <p className="retool-menu-title">クライアントログ</p>
                                </a>
                            </div> */}
                        {canVisibleClientLog &&
                            <div className="retool-menu-item">
                                <button className="btn btn-light btn-block text-left rounded-0 retool-menu-btn retool-menu-btn-default" type="button" data-toggle="collapse" data-target={isMenuOpen && ".clientLogLabeling-menu"} aria-expanded={category == "clientLogLabeling"} >
                                        <p className="retool-menu-title">クライアントログ</p>
                                </button>
                                <div id="reportsMenu" className={ `${category == "clientLogLabeling" ? "collapse show" : "collapse"} clientLogLabeling-menu`}>
                                    <div className="card-body p-0">
                                        <div className="list-group retool-menu-sub-title">
                                            <a href="/clientLogLabeling/actionPattern/index.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 retool-bg-black"><p className="retool-menu-text">アクションパターン</p></a>
                                            <a href="/clientLogLabeling/simplePattern/index.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 retool-bg-black"><p className="retool-menu-text">シンプルパターン</p></a>
                                            <a href="/clientLogLabeling/downloadPattern/index.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 retool-bg-black"><p className="retool-menu-text">ダウンロード</p></a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {canVisibleStandard && canVisibleMasters &&
                            <div className="retool-menu-item">
                                <button className="btn btn-light btn-block text-left rounded-0 retool-menu-btn retool-menu-btn-default" type="button" data-toggle="collapse" data-target={isMenuOpen && ".masters-menu"} aria-expanded={category == "masters"} >
                                        <p className="retool-menu-title">マスタ管理</p>
                                </button>
                                <div id="reportsMenu" className={ `${category == "masters" ? "collapse show" : "collapse"} masters-menu`}>
                                    <div className="card-body p-0">
                                        <div className="list-group retool-menu-sub-title">
                                            <a href="/masters/members.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 retool-bg-black"><p className="retool-menu-text">メンバー管理</p></a>
                                            <a href="/masters/teams.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 retool-bg-black"><p className="retool-menu-text">チーム管理</p></a>
                                            <a href="/masters/projects.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 retool-bg-black"><p className="retool-menu-text">プロジェクト管理</p></a>
                                            <a href="/masters/tasks.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 retool-bg-black"><p className="retool-menu-text">タスク管理</p></a>
                                            <a href="/masters/customers.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 retool-bg-black"><p className="retool-menu-text">顧客管理</p></a>
                                            <a href="/masters/commodities.html" className="list-group-item list-group-item-action border-right-0 border-left-0 rounded-0 border-bottom-0 retool-bg-black"><p className="retool-menu-text">商材管理</p></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        </div>
                        )
    let menuDom;
    if(isMenuOpen){
        menuDom = (
            <div className="retool-menu-open retool-menu-open-default" id="menuDom" style={{height: `${componentMaxHeight}px`}}>
                {sideMenu}
            </div>
            )
    }else if(props.sideMenu.state == "closing"){
        menuDom = (
            <div className="retool-menu-colsing retool-menu-colsing-default"
                 style={{height: `${componentMaxHeight}px`}}
            >
                {sideMenu}
            </div>
            )
    }else{
        menuDom = (
            <div className="retool-menu-close retool-menu-close-default"
                 style={{height: `${componentMaxHeight}px`}}
            ></div>
        )
    }

    return (
        <div className="retool-menu-div" id="sideMenu">

            <nav className="shadow-sm">
                <div className="retool-menu-dom1 retool-menu-dom1-default" id="DOM1" style={{height: `${componentMaxHeight}px`}}>
                    <div className="retool-menu-header retool-menu-header-default">
                        <a className="btn btn-block text-left rounded-0" onClick={(e) => props.onClickDashboard(btnStateToggle)}>
                        <img
                            src="/images/sidebar_logo.png"
                            style={{ height: "35px", width: "35px", marginTop: "6px" }}
                            />
                        </a>
                    </div>
                    {canVisibleStandard &&
                    <>
                    <div className="retool-menu-header retool-menu-header-default">
                       <a className="btn btn-block text-left rounded-0" onClick={(e) => props.onClickDashboard(btnStateToggle)}>
                            <span className="material-icons retool-menu-icon retool-menu-icon-darkorange-default retool-sidemenu-icon-default">dashboard</span>
                        </a>
                    </div>

                    <div className="retool-menu-header retool-menu-header-default">
                        <a className="btn btn-block text-left rounded-0" onClick={(e) => props.onClickResult(btnStateToggle)}>
                            <span className="material-icons retool-menu-icon retool-menu-icon-darkorange-default retool-sidemenu-icon-default">event_available</span>
                        </a>
                    <div className={`  ${(category == "results") ? (props.sideMenu.state == "open" && "collapse show") : "collapse"} ${props.sideMenu.state == "close" &&"collapse"} results-menu`}>
                            <div className="retool-menu-dom1-row2-dumy"></div>
                        </div>
                    </div>
                     <div className="retool-menu-header retool-menu-header-default">
                        <a className="btn btn-block text-left rounded-0" onClick={(e) => props.onClickActivity(btnStateToggle)}>
                            <span className="material-icons retool-menu-icon retool-menu-icon-darkorange-default retool-sidemenu-icon-default">personal_video</span>
                        </a>
                        <div className={`${(category == "activities") ? (props.sideMenu.state == "open" && "collapse show") : "collapse"} ${props.sideMenu.state == "close" &&"collapse"} activities-menu`}>
                            <div className="retool-menu-dom1-row2-dumy"></div>
                        </div>
                    </div>
                     <div className="retool-menu-header retool-menu-header-default">
                        <a className="btn btn-block text-left rounded-0"
                            onClick={(e) => props.onClickPredict(btnStateToggle)}>
                            <span className="material-icons retool-menu-icon retool-menu-icon-darkorange-default retool-sidemenu-icon-default">graphic_eq</span>
                        </a>
                            <div className={`${(category == "predicts") ? (props.sideMenu.state == "open" && "collapse show") : "collapse"} ${props.sideMenu.state == "close" &&"collapse"} predicts-menu`}>
                                <div className="retool-menu-dom1-row4-dumy"></div>
                            </div>
                    </div>

                     <div className="retool-menu-header retool-menu-header-default">
                        <a className="btn btn-block text-left rounded-0"
                            onClick={(e) => props.onClickReport(btnStateToggle)}>
                            <span className="material-icons retool-menu-icon retool-menu-icon-darkorange-default retool-sidemenu-icon-default">bar_chart</span>
                        </a>
                            <div className={`${(category == "reports") ? (props.sideMenu.state == "open" && "collapse show") : "collapse"} ${props.sideMenu.state == "close" &&"collapse"} reports-menu`}>
                                <div className="retool-menu-dom1-row5-dumy"></div>

                                <div className={`collapse reports-menu-operation-forecast ${props.sideMenu.state == "close" &&"collapse"}`}>
                                    <div className="retool-menu-dom1-row3-dumy"></div>
                                </div>
                                <div className={`collapse reports-menu-action-forecast ${props.sideMenu.state == "close" &&"collapse"}`}>
                                    <div className="retool-menu-dom1-row2-dumy"></div>
                                </div>
                                <div className={`collapse reports-menu-progress-forecast ${props.sideMenu.state == "close" &&"collapse"}`}>
                                    <div className="retool-menu-dom1-row2-dumy"></div>
                                </div>
                                <div className={`collapse reports-menu-time-efficiency ${props.sideMenu.state == "close" &&"collapse"}`}>
                                    <div className="retool-menu-dom1-row2-dumy"></div>
                                </div>

                            </div>
                    </div>
                    </>
                    }
                    {/* <div className="retool-menu-header retool-menu-header-default">
                        <a className="btn btn-block text-left rounded-0 " onClick={(e) => props.onClickDashboard(btnStateToggle)}> TODO:イベント作成
                            <span className="material-icons retool-menu-icon retool-menu-icon-darkorange-default retool-sidemenu-icon-default">data_exploration</span>
                        </a>
                    </div> */}
                    {canVisibleClientLog &&
                    <div className="retool-menu-header retool-menu-header-default">
                            <a  className="btn btn-block text-left rounded-0"
                                onClick={(e) => props.onClickDashboard(btnStateToggle)}>
                                    <span className="material-icons retool-menu-icon retool-menu-icon-darkorange-default retool-sidemenu-icon-default">data_exploration</span>
                            </a>
                            <div className={`${(category == "clientLogLabeling") ? (props.sideMenu.state == "open" && "collapse show") : "collapse"} ${props.sideMenu.state == "close" &&"collapse"} clientLogLabeling-menu`}>
                                <div className="retool-menu-dom1-row7-dumy"></div>
                            </div>
                    </div>
                    }

                    {canVisibleStandard && canVisibleMasters &&
                         <div className="retool-menu-header retool-menu-header-default">
                            <a  className="btn btn-block text-left rounded-0"
                                onClick={(e) => props.onClickMaster(btnStateToggle)}>
                                    <span className="material-icons retool-menu-icon retool-menu-icon-darkorange-default retool-sidemenu-icon-default">settings</span>
                            </a>
                            <div className={`${(category == "masters") ? (props.sideMenu.state == "open" && "collapse show") : "collapse"} ${props.sideMenu.state == "close" &&"collapse"} masters-menu`}>
                                <div className="retool-menu-dom1-row6-dumy"></div>
                            </div>
                        </div>
                    }
                </div>
                {menuDom}
            </nav>
            {/*空のアニメーションを動かしonAnimationEndを発火させる*/}
            <button type="button"
                className={`retool-btn-animation-${props.sideMenu.state}`}
                onAnimationEnd={(e) => props.onChangeSideMenu(props.sideMenu.state == "closing" ? "close":"open")}
                >
            </button>

            {/*
            <button type="button"
                    className={`retool-menu-btn-${props.sideMenu.state}`}
                    onClick={(e) => props.onChangeSideMenu(btnStateToggle)}
                    onAnimationEnd={(e) => props.onChangeSideMenu(props.sideMenu.state == "closing" ? "close":"open")}
                    >▶︎button
            </button>
            */}

        </div>

    )
}
